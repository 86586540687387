<template>
  <component :is="beverageData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="beverageData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching beverage data
      </h4>
      <div class="alert-body">
        No beverage found with this beverage id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-beverages-list'}"
        >
          Beverage List
        </b-link>
        for other beverages.
      </div>
    </b-alert>

    <b-tabs
      v-if="beverageData"
      pills
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="BeverageIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t("beverages.beverage") }}</span>
        </template>
        <beverage-edit-tab-account
          :beverage-data="beverageData"
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Information -- >
      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Information</span>
        </template>
        <beverage-edit-tab-information class="mt-2 pt-75" />
      </b-tab-->

      <!-- Tab: Social -- >
      <b-tab>
        <template #title>
          <feather-icon
            icon="Share2Icon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Social</span>
        </template>
        <beverage-edit-tab-social class="mt-2 pt-75" />
      </b-tab-->
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import beverageStoreModule from '../beverageStoreModule'
import BeverageEditTabAccount from './BeverageEditTabAccount.vue'
// import BeverageEditTabInformation from './BeverageEditTabInformation.vue'
// import BeverageEditTabSocial from './BeverageEditTabSocial.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    BeverageEditTabAccount,
    // BeverageEditTabInformation,
    // BeverageEditTabSocial,
  },
  setup() {
    const beverageData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-beverage'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, beverageStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-beverage/fetchBeverage', { id: router.currentRoute.params.id, clone: router.currentRoute.params.clone })
      .then(response => { beverageData.value = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          beverageData.value = undefined
        }
      })

    return {
      beverageData,
    }
  },
}
</script>

<style>

</style>
