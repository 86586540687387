// import { ref, watch, computed } from '@vue/composition-api'
import { ref, computed, watch } from '@vue/composition-api'
import { title } from '@core/utils/filter'
import Vue from 'vue'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'
import i18n from '@/libs/i18n'

export default function useBeveragesList() {
  // Use toast
  const toast = useToast()

  const refBeverageListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'name',
      label: i18n.t('beverages.beverage'),
      formatter: title,
      sortable: true,
    },
    { key: 'price', label: i18n.t('beverages.price'), sortable: true },
    { key: 'items', label: i18n.t('beverages.components'), sortable: true },
    /*
    {
      key: 'company_id',
      label: 'Company',
      formatter: title,
      sortable: true,
    },
    */
    { key: 'actions', label: i18n.t('common.actions') },
  ]
  const perPage = ref(10)
  const totalBeverages = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 50, 250, 500]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref('')
  const companyFilter = ref(store.state.appConfig.filters.company ? store.state.appConfig.filters.company : null)
  const statusFilter = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refBeverageListTable.value ? refBeverageListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalBeverages.value,
    }
  })

  const refetchData = () => {
    refBeverageListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, companyFilter, statusFilter], () => {
    if (!companyFilter.value) {
      localStorage.removeItem('companyId')
    }
    refetchData()
  })

  const fetchBeverages = (ctx, callback) => {
    store
      .dispatch('app-beverage/fetchBeverages', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        company: companyFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { beverages, total } = response.data

        callback(beverages)
        totalBeverages.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching beverages list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteBeverage = item => {
    // console.log(Vue.swal)
    // console.log(store._vm)
    Vue.swal({
      title: 'Are you sure?',
      html: `Beverage <b>${item.name}</b> will be deleted. \nYou won't be able to revert this!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    }).then(result => {
      if (result.value) {
        store
          .dispatch('app-beverage/deleteBeverage', { id: item.id })
          .then(() => {
            refetchData()
            Vue.swal({
              icon: 'success',
              title: 'Deleted!',
              text: `Beverage ${item.name} has been deleted.`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Beverage delete error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    })
  }

  const roleOptions = ref([])
  store.dispatch('app-beverage/fetchRoles')
    .then(response => {
      // console.log('RESP', response.data)
      roleOptions.value = response.data.data
      // console.log('roleOptions', companyOptions)
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching roles list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const companyOptions = ref([])
  store.dispatch('app-beverage/fetchCompanies')
    .then(response => {
      // console.log('RESP', response.data)
      companyOptions.value = response.data.data
      // console.log('companyOptions', companyOptions)
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching companies list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveBeverageRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveBeverageRoleIcon = role => {
    if (role === 'subscriber') return 'BeverageIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'BeverageIcon'
  }

  const resolveBeverageRoleText = role => {
    try {
      return roleOptions.value.find(o => o.value === role).label
    } catch (e) {
      return 'undefined'
    }
  }

  const resolveBeverageStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  const resolveBeverageCompanyText = company => {
    if (!company) {
      return ''
    }
    try {
      return companyOptions.value.find(o => o.value === company).label
    } catch (e) {
      return 'undefined'
    }
  }

  return {
    fetchBeverages,
    tableColumns,
    perPage,
    currentPage,
    totalBeverages,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refBeverageListTable,

    resolveBeverageRoleVariant,
    resolveBeverageRoleIcon,
    resolveBeverageRoleText,
    resolveBeverageStatusVariant,
    resolveBeverageCompanyText,
    refetchData,

    // Extra Filters
    roleFilter,
    companyFilter,
    statusFilter,

    roleOptions,
    companyOptions,

    deleteBeverage,

  }
}
