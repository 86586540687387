import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchBeverages(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/beverage/beverages', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBeverage(ctx, { id, clone }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/beverage/beverages/${id}/${clone}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteBeverage(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/beverage/beverages/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addBeverage(ctx, beverageData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/beverage/beverages', { beverage: beverageData })
          .then(response => resolve(response))
          // eslint-disable-next-line
          .catch(error => {
            // console.log(error)
            // console.log(error.response)
            return reject(error.response)
          })
      })
    },
    fetchCompanies() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/company/filterdata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRoles() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/role/filterdata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchComponents(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/beverage/components/filterdata/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
